<template>
	<div>
		<scabe class="mx-2 my-3 border-b border-black" :name="me.name" />
		<div class="flex items-center font-bold border-b border-black mt-3">
			<util class="w-10 h-10" />
			<div class="ml-3">Utilidades</div>
		</div>
		<div class="mx-2 mt-3">
			<table class="table-auto">
				<thead>
					<tr>
						<th class="border border-black p-1"># Orden de compra</th>
						<th class="border border-black p-1">Utilidad Bs</th>
						<th class="border border-black p-1">Fecha</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(pedido, k) in vendedorUtilidad" :key="k">
						<td
							class="border border-black p-1 text-center"
							:class="
								pedido.pago_vendedor == 1 ? 'text-green-400' : 'text-red-400'
							"
						>
							{{ pedido.id.padStart(4, 0) }}
						</td>
						<td
							class="border border-black p-1 text-center"
							:class="
								pedido.pago_vendedor == 1 ? 'text-green-400' : 'text-red-400'
							"
						>
							{{ formato(pedido.comision_vendedor) }}
						</td>
						<td
							class="border border-black p-1"
							:class="
								pedido.pago_vendedor == 1 ? 'text-green-400' : 'text-red-400'
							"
						>
							{{ formatoFecha(pedido.fecha_entrega) }}
						</td>
					</tr>
					<tr>
						<td class="border border-black p-1 text-center">Total</td>
						<td class="border border-black p-1 text-center">
							{{ formato(calculateTotal) }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<p class="font-bold mx-2 my-3 text-sm">
			Nota: Solo se muestra total de utilidades de pedido completados y
			entregados satisfactoriamente
		</p>
	</div>
</template>
<script>
import util from "@/components/iconoUtilidad.vue";
import scabe from "@/components/subCabeceraV.vue";
import gql from "graphql-tag";
import { formato, formatoFecha } from "@/functions.js";
export default {
	components: {
		util,
		scabe
	},
	data() {
		return {
			me: "",
			Vid: ""
		};
	},
	mounted() {
		this.$store.state.isLoading = true;
		this.$apollo
			.query({
				query: gql`
					query me {
						me {
							name
							id
							vendedores {
								id
								disponible
							}
						}
					}
				`
			})
			.then(data => {
				this.me = data.data.me;
				this.Vid = data.data.me.vendedores[0].id;
				this.$store.state.isLoading = false;
			})
			.catch(error => {
				this.$store.state.isLoading = false;
				console.log(error);
			});
	},
	apollo: {
		vendedorUtilidad: {
			query: gql`
				query($id: ID!, $estatus: String!, $pago_vendedor: String!) {
					vendedorUtilidad(
						vendedor_id: $id
						estatus: $estatus
						pago_vendedor: $pago_vendedor
					) {
						id
						comision_vendedor
						pago_vendedor
						fecha_entrega
					}
				}
			`,
			variables() {
				return {
					id: this.Vid,
					estatus: "entregado",
					pago_vendedor: "0"
				};
			},
			fetchPolicy: "cache-and-network",
			update: data => data.vendedorUtilidad,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	methods: {
		formato,
		formatoFecha
	},
	computed: {
		calculateTotal() {
			var total = 0;
			for (let i in this.vendedorUtilidad) {
				if (this.vendedorUtilidad[i].pago_vendedor == 0) {
					total =
						total + parseFloat(this.vendedorUtilidad[i].comision_vendedor);
				}
			}
			total = total.toFixed(2);
			return total;
		}
	}
};
</script>
