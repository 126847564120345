<template>
    <div>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<polygon points="142,257.1 142,423.1 192,423.1 192,253.5 167,234.3 		"/>
	</g>
	<path d="M199,263"/>
</g>
<g>
	<g>
		<polygon points="301.3,313.4 301.3,421 350.3,421 350.3,267.4 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="66.3,326.6 66.3,421 115.3,421 115.3,284.4 		"/>
	</g>
</g>
<g>
	<g>
		<path d="M66.3,315.1"/>
		<path d="M28.8,268.2"/>
		<path d="M-8.7,221.4"/>
	</g>
</g>
<g>
	<g>
		<polygon points="219.5,269.7 219.5,423.1 268.5,423.1 268.5,309.7 		"/>
	</g>
</g>
<g>
	<g>
		<polygon points="383.1,234.8 383.1,421 432.1,421 432.1,188.6 		"/>
	</g>
</g>
<polyline points="64.2,300 168.4,204.1 279.7,292.1 442.3,143.5 462.2,161.1 469.2,94 398.7,102.2 417.4,121 278.8,245.6 
	167.5,155.4 61.5,250.5 "/>
</svg>

    </div>
</template>
<script>
export default {
    name:'utilidad',
}
</script>